<template lang="html">
  <section>
    <div v-if="!lines || lines.length === 0">
      {{ $t('No wheels registered for this season') }}
    </div>
    <div v-if="lines && lines.length > 0">
    <div class="header">
      <div class="placement"> &nbsp;</div>
      <div class="product"> &nbsp;</div>
      <div class="depth"> &nbsp;</div>
      <div class="in-stock">
        {{ $t('In stock?') }}
      </div>
    </div>

    <div v-for="(line, i) in lines" :key="i" class="line">
      <div class="placement">
        <span>{{ line.placement.name }}</span>
      </div>
      <div class="product">
        {{ line.brand }}
        <br />
        {{ line.dimension }}
      </div>
      <div class="depth">
        <a @click="openDialog(line)"> {{ line.mm }} mm </a>
      </div>
      <div class="in-stock">
        <a :class="line.inStock ? 'in-stock' : 'not-in-stock'" @click="toggleLineInStock(line)">
          <i class="fa" :class="line.inStock ? 'fa-check-circle' : 'fa-times-circle'" />
        </a>
      </div>
    </div>

    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <a v-bind="attrs" v-on="on"> Open Dialog </a>
      </template>
      <v-card>
        <v-card-title class="headline">
          {{ $t('Change tread depth') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            id="tread-depth"
            v-model="newTreadDepth"
            outlined
            pattern="[0-9\.]*"
            type="text"
            :label="$t('Tread depth')"
            :placeholder="oldTreadDepth"
            suffix="mm"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="update()">
            {{ $t('Update') }}
          </v-btn>
          <v-btn @click="dialog = false">
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'TyreHotelLines',
    // eslint-disable-next-line vue/require-prop-types
    props: ['lines', 'season'],
    data() {
      return {
        activeLine: null,
        oldTreadDepth: '',
        newTreadDepth: '',
        dialog: false,
      }
    },
    mounted() {
      console.log('lines:', this.lines)
    },
    methods: {
      update() {
        this.activeLine.mm = parseFloat(this.newTreadDepth)
        this.dialog = false
      },
      toggleLineInStock(line) {
        const seasons = { summer: 1, winter: 2 }
        const seasonInStock = seasons[this.season]
        if (line.season !== seasonInStock) {
          alert('You must switch stock to this season before you can toggle individual tyres')
          return
        }
        line.inStock = !line.inStock
        // this.$emit('change', line)
      },
      openDialog(line) {
        this.oldTreadDepth = line ? '' + line.mm : ''
        this.newTreadDepth = ''
        this.activeLine = line
        this.dialog = true
      },
    },
  }
</script>

<style lang="sass" scoped>
  section
    display: table
    width: 100%

    .header
      display: table-row

      > div
        display: table-cell
        padding-left: 5px
        padding-right: 5px
        font-weight: bold
        font-size: 12px
        color: #888

      .depth
        text-align: right

      .in-stock
        text-align: center

    .line
      display: table-row

      > div
        display: table-cell
        padding-left: 5px
        padding-right: 5px
        padding-bottom: 15px
        height: 40px
        line-height: 20px
        vertical-align: top

      .placement
        > span
          display: block
          width: 40px
          background: #ddd
          line-height: 40px
          vertical-align: middle
          text-align: center
      //.product
      .depth
        white-space: nowrap
        text-align: right
        line-height: 40px
        vertical-align: middle

      .in-stock
        text-align: center

        > a
          color: white
          font-weight: bold
          font-size: 1.5rem
          line-height: 40px
          vertical-align: middle

          &.in-stock
            color: green

          &.not-in-stock
            color: red

          i
            vertical-align: -5%
</style>
